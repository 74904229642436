import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import CTABlock from "../../components/blocks/cta_block"
import GridFilters from "../../components/gridclubs"

const ClubSimplePage = ({ data }) => {
  console.log(data)
  const page = data.wagtail.page

  return (
    <Layout
      isContent={true}
      subMenu={data.wagtail.page.ancestors[0].children}
      parent={"clubs"}
      siteTitle={page.title}
    >
      <section className="breadcrumb-area breadcrumb-sub-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb nav-breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/clubs">Clubs</Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="about-area main-area ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>{page.title}</h1>
              <p>{page.descriptionSimple}</p>
            </div>
          </div>
          <div
            className="c-title-content find-club"
            dangerouslySetInnerHTML={{
              __html: page.bodySafe,
            }}
          />
        </div>
      </section>
      <div className="clearfix"></div>
      <GridFilters data={data.wagtail.page} />
      {data.wagtail.page.fullContent &&
        data.wagtail.page.fullContent.length > 0 && (
          <CTABlock data={data.wagtail.page.fullContent[0]}></CTABlock>
        )}
    </Layout>
  )
}

ClubSimplePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query {
    wagtail {
      page(id: 22080) {
        ... on FindClub {
          id
          slug
          body
          bodySafe
          descriptionSimple
          button {
            name
            text
            url
          }
          ancestors(order: "-depth", limit: 1) {
            ... on ClubsPage {
              children {
                title
                slug
                ... on SimplePage {
                  live
                  isProtected
                }
                ... on FindClub {
                  live
                  isProtected
                }
              }
              button {
                text
                name
                url
              }
            }
          }
          children {
            ... on ItemClub {
              id
              slug
              name
              abbreviation
              region
              website
              description
              address
              city
              province
              status
              postalcode
              phone
              fax
              email
              university
              presidentfirstname
              presidentlastname
              presidentphone
              presidentfax
              presidentemail
              headcoachfirstname
              headcoachlastname
              headcoachphone
              headcoachfax
              headcoachemail
              clubfirstname
              clublastname
              clubphone
              clubfax
            }
          }

          title
          fullContent {
            ... on CTABlock {
              id
              title
              image {
                ... on ImageObjectType {
                  src
                  file
                  width
                  height
                }
              }
              description
              orientation
              url
              urlName
            }
          }
        }
      }
    }
  }
`
export default ClubSimplePage
