import React, { useState, useEffect, useCallback } from "react"
import { Link } from "gatsby"
import Moment from "moment"
import Select from "./Select/index"
import Loader from "react-loader-spinner"
import debounce from "lodash.debounce"
import Axios from "axios"

const alphaOrder = (a, b) => {
  return a.localeCompare(b)
}

const getData = (url, payload, setData, cbLoading) => {
  const bodyFormData = new FormData()
  bodyFormData.append("query_param", payload.query_param)
  Axios.post(url, bodyFormData)
    .then(data => {
      const responseData = data?.data?.data ?? []
      if (responseData) {
        const ids = responseData.map(item => item.id.toString())
        setData(ids)
        // cbLoading()
      }
    })
    .catch(err => {
      setData([])
      console.log(err)
      // cbLoading()
    })
}

const debouncedFetchData = debounce((query, payload, cb, cbLoading) => {
  getData(query, payload, cb, cbLoading)
}, 500)

const GridFilters = props => {
  const [data, setData] = useState([])
  const [serverIds, setServerIds] = useState([])
  const [initialData, setInitialData] = useState([])
  const [page, setPage] = useState()
  const [loadMore, setLoadmore] = useState(20)
  const [filter, setFilter] = useState()
  const [regions, setRegions] = useState([])
  // const [typeClubs, setTypeClubs] = useState([])
  const [cities, setCities] = useState([])
  const [filterCity, setFilterCity] = useState()
  const [text, setText] = useState()
  const [loading, setLoading] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [loadingRedirect, setLoadingRedirect] = useState(false)

  useEffect(() => {
    if (text && text.length > 0) {
      setLoading(true)
      const url = `${process.env.GATSBY_SERVER_SEARCH_ITEM}`
      const payload = { query_param: text }
      debouncedFetchData(url, payload, setServerIds, () => {})
    } else {
      // setLoading(false)
    }
  }, [text])

  const search = useCallback(
    event => {
      setText(event.target.value)
      // if(!loading){
      //   setLoading(event.target.value)
      // }
    },
    [loading]
  )

  useEffect(() => {
    const allRegions = props.data.children.map(item =>
      item.region?.toLowerCase()
    )
    let uniqueRegions = [...new Set(allRegions)]
    uniqueRegions = uniqueRegions.filter(item => item && item.length > 0)
    const allTypeClubs = props.data.children.map(item => item.description)
    let uniqueTypeClubs = [...new Set(allTypeClubs)]
    uniqueTypeClubs = uniqueTypeClubs.filter(item => item && item.length > 0)
    uniqueTypeClubs.sort(alphaOrder)
    const allCities = props.data.children.map(item => item.city?.toLowerCase())
    let uniqueCities = [...new Set(allCities)]
    uniqueCities = uniqueCities.filter(item => item && item.length > 0)
    uniqueCities.sort(alphaOrder)

    const {
      data: { children },
    } = props

    const dataParsed = children.filter(item => item.status)
    const orderName = "name"
    const orderUniv = "university"
    dataParsed.sort((a, b) => {
      if (!a[orderName] && !b[orderName] && !a[orderUniv] && !b[orderUniv]) {
        return a.id > b.id
      }
      const string1 = a[orderName]
        ? a[orderName].toLowerCase()
        : a[orderUniv]
        ? a[orderUniv].toLowerCase()
        : ""
      const string2 = b[orderName]
        ? b[orderName].toLowerCase()
        : b[orderUniv]
        ? b[orderUniv].toLowerCase()
        : ""
      return (string1 > string2) - (string1 < string2)
    })

    setData(dataParsed)
    setInitialData(dataParsed)
    setRegions(uniqueRegions)
    // setTypeClubs(uniqueTypeClubs)
    setCities(uniqueCities)
    setPage(props.data)
  }, [props])

  const onClick = useCallback(() => {
    setLoadingMore(true)
    const min = 0.5
    const max = 0.7
    const rand = Math.floor(Math.random() * (max - min + 0.7) + min)
    // setLoading(true)
    setTimeout(function() {
      // setLoading(false)
      setLoadmore(loadMore + 20)
      setLoadingMore(false)
    }, rand * 1000)
    // clearTimeout(timeout)
  }, [loadMore, loadingMore])

  const getLoadMore = useCallback(() => {
    const total = data ? data.length : 0
    if (total < 20) {
      return null
    }
    if (loadMore >= total) {
      return <></>
    }
    return (
      <button onClick={onClick} className="button-load-more">
        Load More
      </button>
    )
  }, [onClick, loadMore, data])

  useEffect(() => {
    Moment.locale("en")
  }, [])

  useEffect(() => {
    const allCities = data.map(item => item.city?.toLowerCase())
    let uniqueCities = [...new Set(allCities)]
    uniqueCities = uniqueCities.filter(item => item && item.length > 0)
    uniqueCities.sort(alphaOrder)
    setCities(uniqueCities)
  }, [filter, data])

  useEffect(() => {
    const allRegions = data.map(item => item.region?.toLowerCase())
    let uniqueRegions = [...new Set(allRegions)]
    uniqueRegions = uniqueRegions.filter(item => item && item.length > 0)
    setRegions(uniqueRegions)
  }, [filterCity, data])

  useEffect(() => {
    let dataFiltered = initialData
    dataFiltered = initialData.filter((item, index) => {
      return !filter || item.region.toLowerCase() === filter.toLowerCase()
    })
    if (filterCity) {
      dataFiltered = dataFiltered.filter(
        item => item.city?.toLowerCase() === filterCity.toLowerCase()
      )
    }
    if (text?.length > 0) {
      dataFiltered = dataFiltered.filter(item => serverIds.includes(item.id))
    }
    setData(dataFiltered)
    setTimeout(() => setLoading(false), 1200)
  }, [filter, initialData, text, filterCity, serverIds])

  const renderFilters = useCallback(() => {
    return (
      <div className="select-items-area">
        <div className="box">
          <p className="p-bold pb-10" style={{ marginTop: "-10px" }}>
            Search
          </p>
          <div className="search-box find-club">
            <input
              type="search"
              placeholder="Enter keyword"
              value={text}
              onChange={search}
            />
            <button>
              <img src={`../../images/search.png`} alt="src" />
            </button>
          </div>
        </div>

        <div className="box">
          <h6>Region</h6>
          <Select
            name="filter"
            value={filter}
            handleChange={({ name, value }) => setFilter(value)}
            placeholder="All"
            selectOptions={["all"].concat(
              regions.map((item, index) => `${item}`)
            )}
          />
        </div>
        <div className="box">
          <h6>City</h6>
          <Select
            name="filter"
            value={filterCity}
            handleChange={({ _, value }) => setFilterCity(value)}
            placeholder="All"
            selectOptions={["all"].concat(
              cities.map((item, index) => `${item}`)
            )}
          />
        </div>
      </div>
    )
  }, [filter, regions, cities, filterCity, text, serverIds, data])

  const formatUrl = useCallback((e, url) => {
    e.preventDefault()
    setLoadingRedirect(true)
    if (!url.includes("http")) {
      const tmp = `https://${url}`
      fetch(tmp)
        .then(() => {
          window.open(tmp, "_blank", "noopener,noreferrer")
          setLoadingRedirect(false)
        })
        .catch(err => {
          window.open(`http://${url}`, "_blank", "noopener,noreferrer")
          setLoadingRedirect(false)
        })
    } else {
      window.open(`${url}`, "_blank", "noopener,noreferrer")
    }
  })

  return (
    <section className="events-grid">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {renderFilters()}
            {loadingRedirect && (
              <div className="row">
                <div className="col-12 align-items-center text-center">
                  <>
                    <div className="loader-custom" />
                    <br />
                    Redirecting
                  </>
                </div>
              </div>
            )}
            <div className="table-area">
              {!loading && (
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Club Name</th>
                      <th scope="col">Website Address</th>
                      <th scope="col">Type Of Club</th>
                      <th scope="col">Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => {
                      if (index <= loadMore && item.status === "true") {
                        return (
                          <tr
                            key={`${item.slug}${index}`}
                            className="club-table-tr"
                          >
                            <th scope="row" style={{ width: "25%" }}>
                              <Link to={`/clubs/${page.slug}/${item.slug}`}>
                                {item.name || item.university}
                              </Link>
                            </th>
                            <td
                              style={{ width: "30%", wordBreak: "break-all" }}
                            >
                              <a
                                href="#"
                                onClick={e => formatUrl(e, item.website)}
                                style={{
                                  textTransform: "lowercase",
                                  marginRight: "10px",
                                }}
                              >
                                {item.website}
                              </a>
                            </td>
                            <td style={{ width: "15%" }}>
                              <h6>{item.description}</h6>
                            </td>
                            <td style={{ width: "30%" }}>
                              {item.address && (
                                <a
                                  rel="noreferrer"
                                  target="_blank"
                                  href={`https://www.google.com/maps/search/${item.address}`}
                                >
                                  {item.address}{" "}
                                  {`${item.city}, ${item.postalcode}`}
                                </a>
                              )}
                            </td>

                            {/* <td style={{ width: "15%" }}> */}
                            {/*   <h6> */}
                            {/*     <a */}
                            {/*       href={`phone://${item.phone}`} */}
                            {/*       target="_blank" */}
                            {/*     > */}
                            {/*       {item?.phone && item.phone !== "()" */}
                            {/*         ? item.phone */}
                            {/*         : "-"} */}
                            {/*     </a> */}
                            {/*   </h6> */}
                            {/* </td> */}
                            {/* <td style={{ width: "10%" }}> */}
                            {/*   <h6>{item.region}</h6> */}
                            {/* </td> */}
                          </tr>
                        )
                      }
                      return ""
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 align-items-center text-center">
            {(loading || loadingMore) && (
              <>
                <Loader type="Puff" color="#00aad8" height={100} width={100} />
                <br />
              </>
            )}
            {getLoadMore()}
          </div>
        </div>
      </div>
    </section>
  )
}

export default GridFilters
